import { useRequest } from 'ahooks';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useEnvironment } from '../EnvironmentProvider';
import Environment from '../EnvironmentProvider/Environment';
import Loading from '../Loading';

type Config = {
  api: Record<string, string>;
  environment: Environment;
};

type Configs = Record<Environment, Config>;

export const ConfigContext = createContext<Configs | undefined>(undefined);

export const useConfigs = (): Configs => {
  const configs = useContext(ConfigContext);

  if (!configs) {
    throw new Error('No config');
  }

  return configs;
};

export const useIntendedConfig = (): Config => {
  const configs = useConfigs();

  return Object.entries(configs)[0][1];
};

export const useConfig = (): Config => {
  const configs = useConfigs();
  const [environment] = useEnvironment();
  const intendedConfig = useIntendedConfig();
  const config = environment && configs[environment];

  return config || intendedConfig;
};

export const useApiConfig = (): Config['api'] => useConfig().api;

const loadConfig = (environment?: string) =>
  axios({
    url: `/${environment || 'config'}.json`,
    params: { cache: CACHE },
  }).then(({ data }: { data: Config }) => data);

const ConfigProvider: FC = ({ children }) => {
  const [environment, setEnvironment] = useEnvironment();
  const [configs, setConfigs] = useState<Configs>();

  const { data: config, error, loading, run } = useRequest<Config>(loadConfig);

  useEffect(() => {
    // Load config if it wasn't already loaded before
    if (environment && configs && !configs[environment]) {
      void run(environment);
    }
  }, [environment, run, configs]);

  useEffect(() => {
    // Need to initialize environment based on config.json
    if (!environment && config) {
      setEnvironment(config.environment);
    }
  }, [config, environment, setEnvironment]);

  useEffect(() => {
    if (config) {
      setConfigs(
        (prevState) =>
          ({
            ...prevState,
            [config.environment]: config,
          } as Configs), // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/26635)
      );
    }
  }, [config, setEnvironment]);

  if (error) {
    throw new Error('Error during config fetch');
  }

  if ((loading && !configs) || !configs) {
    return <Loading />;
  }

  return (
    <ConfigContext.Provider value={configs}>{children}</ConfigContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node,
};

ConfigProvider.displayName = 'ConfigProvider';

export default ConfigProvider;
