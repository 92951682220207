import { ReloadOutlined } from '@ant-design/icons';
import type { FallbackRender } from '@sentry/react/esm/errorboundary';
import { Button, Result } from 'antd';
import React from 'react';

const ErrorBoundaryFallback: FallbackRender = (errorData) => {
  const { error } = errorData;

  const title = 'Error';

  document.title = title;

  return (
    <Result
      extra={
        <Button
          href={window.location.href}
          icon={<ReloadOutlined />}
          type="primary"
        >
          Reload
        </Button>
      }
      status={500}
      subTitle={error?.toString()}
      title={title}
    />
  );
};

export default ErrorBoundaryFallback;
