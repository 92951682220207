import * as Sentry from '@sentry/react';
import axios from 'axios';
import { parseDomain, ParseResultType } from 'parse-domain';
import PropTypes from 'prop-types';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useAsync } from 'react-async';

import { useApiConfig } from '../ConfigProvider';
import Loading from '../Loading';

import Maintenance from './Maintenance';

type Organization = {
  auth: {
    clients: Record<string, string>;
    domain: string;
  };
  company: string;
  datagonUrl: string;
  surveyorUrl: string;
  farmerUrl: string;
  features?: Record<string, boolean>;
  feedApi: string;
  id: string;
  settings?: Record<string, string>;
  styles?: {
    colors: {
      primary: string;
    };
  };
};

export const OrganizationContext = createContext<Organization | undefined>(
  undefined,
);

export const useOrganization = (): Organization => {
  const organization = useContext(OrganizationContext);

  if (!organization) {
    throw new Error('No organization');
  }

  return organization;
};

export const useOrganizationFeatures = (): Organization['features'] => {
  const { features } = useOrganization();

  if (!features) {
    throw new Error('No organization features');
  }

  return features;
};

export const useOrganizationSettings = (): Organization['settings'] => {
  const { settings } = useOrganization();

  if (!settings) {
    throw new Error('No organization settings');
  }

  return settings;
};

export const useOrganizationStyles = (): Organization['styles'] => {
  const { styles } = useOrganization();

  if (!styles) {
    throw new Error('No organization styles');
  }

  return styles;
};

export const useOrganizationAuth = (): Organization['auth'] => {
  const { auth } = useOrganization();

  if (!auth) {
    throw new Error('No organization authentication');
  }

  return auth;
};

// Fallback if on a development domain
const dev = ['amazonaws.com', 'localhost', 'swaarm.com'].some((hostname) =>
  window.location.hostname.includes(hostname),
);

const OrganizationProvider: FC = ({ children }) => {
  const parseResult = useMemo(
    () => parseDomain(dev ? 'swaarm.com' : window.location.hostname),
    [],
  );

  let domain = '';

  if (parseResult.type === ParseResultType.Listed) {
    if (!parseResult.domain) {
      throw new Error('Invalid domain');
    }

    domain = parseResult.domain;
  }

  const api = useApiConfig();

  const { data: organization, isLoading: loading } = useAsync<Organization>({
    promiseFn: useCallback(async () => {
      const config = await axios({
        url: `${api.organization}/${domain}.json`,
        params: { cache: CACHE },
      }).catch(function (error) {
        // If a config couldn't be loaded / is not available its not a reason to fail hard
        Sentry.withScope(() => {
          Sentry.captureException(
            new Error(
              `Loading config for organization for key ${domain} failed!`,
            ),
            error,
          );
        });
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return config?.data as Organization;
    }, [api.organization, domain]),
  });

  if (loading && !organization) {
    return <Loading />;
  }

  if (!organization) {
    return <Maintenance />;
  }

  return (
    <OrganizationContext.Provider value={organization}>
      {children}
    </OrganizationContext.Provider>
  );
};

OrganizationProvider.propTypes = {
  children: PropTypes.node,
};

OrganizationProvider.displayName = 'OrganizationProvider';

export default OrganizationProvider;
