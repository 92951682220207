import { useFavicon } from 'ahooks';
import React, { lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import routes from '../../paths.json';
import { useOrganization } from '../OrganizationProvider';

const Auth = lazy(() =>
  import(/* webpackChunkName: "auth" */ '../../routes/Auth'),
);

const Home = lazy(() =>
  import(/* webpackChunkName: "home" */ '../../routes/Home'),
);

const Welcome = lazy(() =>
  import(/* webpackChunkName: "login" */ '../../routes/Welcome'),
);

const App = () => {
  const { pathname } = useLocation();
  const { logoUrl } = useOrganization();

  useFavicon(logoUrl);

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path={routes.login}>
        <Welcome />
      </Route>
      <Route path={routes.auth}>
        <Auth />
      </Route>
      <Route path={routes.home}>
        <Home />
      </Route>
    </Switch>
  );
};

App.displayName = 'App';

export default App;
