type PayloadFunction<Payload> = (token: string) => Payload;

const defaultPayloadMemoize = <Payload>(
  func: PayloadFunction<Payload>,
): PayloadFunction<Payload> => {
  let prevToken: string;
  let prevPayload: Payload;

  return (token) => {
    if (token === prevToken) {
      return prevPayload;
    }

    prevToken = token;
    prevPayload = func(token);

    return prevPayload;
  };
};

export default defaultPayloadMemoize;
