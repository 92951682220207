import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useOrganizationSettings } from '../OrganizationProvider';

window.dataLayer = window.dataLayer || [];

function gtag() {
  // analytics wants exactly arguments not args
  window.dataLayer.push(arguments);
}

gtag('js', new Date());

let appended = false;

const GoogleAnalytics = () => {
  const { analyticsId: trackingId } = useOrganizationSettings();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!appended && trackingId) {
      const script = document.createElement('script');

      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;

      document.body.appendChild(script);

      appended = true;
    }
  }, [trackingId]);

  useEffect(() => {
    if (trackingId) {
      gtag('config', trackingId, { page_path: pathname });
    }
  }, [trackingId, pathname]);

  return null;
};

GoogleAnalytics.displayName = 'GoogleAnalytics';

export default GoogleAnalytics;
