import { Button, Result } from 'antd';
import httpStatus, { NOT_FOUND } from 'http-status';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import routes from '../../paths.json';
import HeadTitle from '../HeadTitle';

const Exception = ({
  actions,
  desc: descProp,
  title: titleProp,
  type,
  ...rest
}) => {
  const title = String(titleProp || type);
  const desc = descProp || httpStatus[`${type}_MESSAGE`];

  return (
    <>
      <HeadTitle>{title}</HeadTitle>
      <Result
        extra={
          actions || (
            <Link to={routes.home}>
              <Button type="primary">Back to Homepage</Button>
            </Link>
          )
        }
        status={String(type)}
        subTitle={desc}
        title={title}
        {...rest}
      />
    </>
  );
};

Exception.propTypes = {
  actions: PropTypes.node,
  desc: PropTypes.node,
  title: PropTypes.node,
  type: PropTypes.number,
};

Exception.defaultProps = {
  actions: undefined,
  desc: undefined,
  title: undefined,
  type: NOT_FOUND,
};

export default Exception;
