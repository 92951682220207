import {
  BrowserOptions,
  init,
  reactRouterV5Instrumentation,
} from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { useSessionStorageState } from 'ahooks';
import PropTypes from 'prop-types';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useHistory } from 'react-router';

import Environment from './Environment';
type EnvironmentState = [Environment | undefined, (value: Environment) => void];

export const EnvironmentContext = createContext<EnvironmentState>([
  undefined,
  () => null,
]);

export const useEnvironment = (): EnvironmentState =>
  useContext(EnvironmentContext);

const EnvironmentProvider: FC = ({ children }) => {
  const history = useHistory();
  const [environment, setEnvironment] = useSessionStorageState<
    Environment | undefined
  >('environment');

  useEffect(() => {
    if (!environment || environment === Environment.Testing) {
      init();

      return;
    }

    const options: BrowserOptions = {
      dsn: 'https://77d921620545458191329a9011c25c45@sentry.io/1301135',
      environment,
    };

    if (environment === Environment.Production) {
      options.release = `cockpit-${VERSION}`;
      options.integrations = [
        new Integrations.BrowserTracing({
          routingInstrumentation: reactRouterV5Instrumentation(history),
        }),
      ];
      options.tracesSampleRate = 0.05;
    }

    init(options);
  }, [environment, history]);

  const setPersistedEnvironment = useCallback(
    (value: Environment) => {
      setEnvironment(value);
    },
    [setEnvironment],
  );

  const value = useMemo<EnvironmentState>(
    () => [environment, setPersistedEnvironment],
    [environment, setPersistedEnvironment],
  );

  return (
    <EnvironmentContext.Provider value={value}>
      {children}
    </EnvironmentContext.Provider>
  );
};

EnvironmentProvider.propTypes = {
  children: PropTypes.node,
};

EnvironmentProvider.displayName = 'EnvironmentProvider';

export default EnvironmentProvider;
