import { useTitle } from 'ahooks';
import PropTypes from 'prop-types';
import { FC } from 'react';

/**
 * @deprecated
 * use useTitle hook from ahooks package
 */
const HeadTitle: FC<{ children: string }> = ({ children }) => {
  useTitle(children);

  return null;
};

HeadTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

HeadTitle.displayName = 'HeadTitle';

export default HeadTitle;
