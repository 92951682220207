import { ReloadOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Button } from 'antd';
import React, { Component } from 'react';

import Exception from '../Exception';

const withException = (WrappedComponent) => {
  class WithException extends Component {
    static getDerivedStateFromError(error) {
      return { error };
    }

    constructor(props) {
      super(props);

      this.state = {};
    }

    componentDidCatch(error, info) {
      Sentry.withScope((scope) => {
        scope.setExtra('componentStack', info.componentStack);

        Sentry.captureException(error);
      });
    }

    render() {
      const { error } = this.state;

      if (error) {
        let title = 'Error';
        let desc = error.toString();

        if (error.graphQLErrors) {
          title = 'GraphQL Error';
          desc = error.graphQLErrors.map(({ message }, index) => (
            <p key={index}>{message}</p>
          ));
        }

        return (
          <Exception
            actions={
              <Button
                icon={<ReloadOutlined />}
                onClick={() => window.location.reload(true)}
                type="primary"
              >
                Reload
              </Button>
            }
            desc={desc}
            title={title}
            type={500}
          />
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  WithException.displayName = `withException(${
    WrappedComponent.displayName || 'Component'
  })`;

  return WithException;
};

export default withException;
