import { configureScope } from '@sentry/react';
import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { initRequest } from '../../api/request';
import Permission from '../../constants/Permission';
import paths from '../../paths.json';
import { useApiConfig } from '../ConfigProvider';
import { useOrganization, useOrganizationAuth } from '../OrganizationProvider';

import defaultPayloadMemoize from './defaultPayloadMemoize';

const audience = 'https://api.weq.host/';
const connection = 'Cockpit';

type Authentication = {
  accessToken?: string;
  authenticated: boolean;
  authorize: (connection: string) => void;
  changePassword: (email: string) => Promise<void>;
  idToken?: string;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  parseHash: () => void;
  renewToken: () => void;
};

type AuthenticationState = {
  accessToken: string;
  expiresAt: number;
  idToken: string;
};

const contextError = () => {
  throw new Error('No <AuthProvider />');
};

const contextErrorPromise = () => Promise.reject(contextError());

export const AuthenticationContext = createContext<Authentication>({
  authenticated: false,
  authorize: contextError,
  changePassword: contextErrorPromise,
  login: contextErrorPromise,
  logout: contextErrorPromise,
  parseHash: contextErrorPromise,
  renewToken: contextErrorPromise,
});

export const useAuthentication = (): Authentication =>
  useContext(AuthenticationContext);

type AccessTokenPayload = {
  permissions: Permission[];
};

const getAccessTokenPayload = defaultPayloadMemoize((token) =>
  jwtDecode<AccessTokenPayload>(token),
);

export const usePermissions = (): Permission[] => {
  const { accessToken } = useAuthentication();

  return useMemo(() => {
    if (accessToken) {
      const { permissions = [] } = getAccessTokenPayload(accessToken);

      return permissions;
    }

    return [];
  }, [accessToken]);
};

type IdTokenPayload = {
  'https://settings'?: {
    manager_id?: number;
  };
  email: string;
  name: string;
  sub: string;
};

const getIdTokenPayload = defaultPayloadMemoize((token) =>
  jwtDecode<IdTokenPayload>(token),
);

export const useUser = (): IdTokenPayload => {
  const { idToken } = useAuthentication();

  if (!idToken) {
    throw new Error('No idToken');
  }

  return getIdTokenPayload(idToken);
};

const getExpiresAt = (expiresIn: number) =>
  new Date(Date.now() + expiresIn * 1e3).getTime();

const AuthenticationProvider: FC = ({ children }) => {
  const api = useApiConfig();
  const { farmerUrl, surveyorUrl } = useOrganization();

  const {
    clients: { cockpit: clientID },
    domain,
  } = useOrganizationAuth();

  const webAuth = useMemo(
    () =>
      new auth0.WebAuth({
        audience,
        clientID,
        domain,
        responseType: 'token id_token',
        scope: 'app_metadata email openid profile roles',
      }),
    [clientID, domain],
  );

  const timeoutRef = useRef<number | null>(null);

  const [authentication, setAuthenticationState] = useState<
    AuthenticationState | undefined
  >(() => {
    const item = localStorage.getItem('authentication');

    if (!item) {
      return;
    }

    try {
      return JSON.parse(item) as AuthenticationState;
    } catch (e) {
      return undefined;
    }
  });

  const setAuthentication = useCallback(
    (result: { accessToken: string; expiresIn: number; idToken: string }) => {
      setAuthenticationState({
        accessToken: result.accessToken,
        expiresAt: getExpiresAt(result.expiresIn),
        idToken: result.idToken,
      });
    },
    [],
  );

  const authorize = (connection: string) => {
    const { origin } = window.location;
    const redirect =
      new URLSearchParams(window.location.search).get('redirect') || paths.home;

    const params = new URLSearchParams({
      redirect,
      v: VERSION,
    });

    const redirectUri = `${origin}${paths.auth}?${params.toString()}`;

    webAuth.authorize({
      connection,
      redirectUri,
    });
  };

  const authenticated = useMemo(() => {
    if (!authentication) {
      return false;
    }

    return Date.now() <= new Date(authentication.expiresAt).getTime();
  }, [authentication]);

  const changePassword = (email: string): Promise<void> =>
    new Promise((resolve, reject) => {
      webAuth.changePassword(
        {
          connection,
          email,
        },
        (error) => {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        },
      );
    });

  const login = useCallback(
    (username: string, password: string): Promise<void> =>
      new Promise((resolve, reject) => {
        webAuth.client.login(
          {
            password,
            realm: connection,
            username,
          },
          (error, result) => {
            if (error) {
              reject(error);
            } else {
              setAuthentication(result);

              resolve();
            }
          },
        );
      }),
    [webAuth, setAuthentication],
  );

  const logout = () => {
    localStorage.removeItem('authentication');

    webAuth.logout({
      returnTo: `${window.location.origin}${paths.login}`,
    });
  };

  const parseHash = useCallback(
    () =>
      new Promise((resolve, reject) => {
        webAuth.parseHash({ hash: window.location.hash }, (err, result) => {
          if (err) {
            return reject(new Error(err.errorDescription));
          }

          if (
            !result ||
            !result.accessToken ||
            !result.expiresIn ||
            !result.idToken
          ) {
            return reject(new Error('No decoded hash'));
          }

          setAuthentication({
            accessToken: result.accessToken,
            expiresIn: result.expiresIn,
            idToken: result.idToken,
          });

          resolve();
        });
      }),
    [webAuth, setAuthentication],
  );

  const renewToken = useCallback(() => {
    timeoutRef.current = null;

    return new Promise((resolve, reject) => {
      webAuth.checkSession({}, (error, result) => {
        if (error) {
          setAuthenticationState(undefined);
          reject(error);
        } else {
          setAuthentication(result);
          resolve();
        }
      });
    });
  }, [webAuth, setAuthentication]);

  useEffect(() => {
    if (!authentication) {
      localStorage.removeItem('authentication');

      return;
    }

    configureScope((scope) => {
      scope.setUser(getIdTokenPayload(authentication.idToken));
    });

    localStorage.setItem('authentication', JSON.stringify(authentication));

    timeoutRef.current = window.setTimeout(
      () => void renewToken().catch(console.warn),
      Math.max(0, authentication.expiresAt - Date.now() - 60e3),
    );

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [authentication, renewToken]);

  useEffect(() => {
    if (authentication?.accessToken) {
      initRequest({
        accessToken: authentication.accessToken,
        api: {
          ...api,
          farmer: farmerUrl,
          surveyor: surveyorUrl,
        },
      });
    }
  }, [api, authentication?.accessToken, farmerUrl, surveyorUrl]);

  return (
    <AuthenticationContext.Provider
      value={{
        accessToken: authentication?.accessToken,
        authenticated,
        authorize,
        changePassword,
        idToken: authentication?.idToken,
        login,
        logout,
        parseHash,
        renewToken,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthenticationProvider.displayName = 'AuthenticationProvider';

export default AuthenticationProvider;
