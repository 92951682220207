import { ConfigProvider } from 'antd';
import en from 'antd/es/locale/en_US';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { FC } from 'react';

// https://ant.design/components/date-picker/#How-to-modify-start-day-of-week
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const locale = {
  ...en,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  Pagination: {
    ...en.Pagination,
    jump_to: 'Page #',
  },
};

const form = {
  validateMessages: {
    required: 'Required',
  },
};

const pageHeader = {
  ghost: false,
};

const AntConfigProvider: FC = ({ children }) => (
  <ConfigProvider form={form} locale={locale} pageHeader={pageHeader}>
    {children}
  </ConfigProvider>
);

AntConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AntConfigProvider;
