import { ToolOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import React, { FC } from 'react';

import styles from './styles.module.less';

const Maintenance: FC = () => (
  <div className={styles.maintenance}>
    <Result
      extra={
        <Button onClick={() => window.location.reload()} type="primary">
          Reload
        </Button>
      }
      icon={<ToolOutlined />}
      subTitle="Thank you for being patient while we do some work on the site. Please check back soon."
      title="Maintenance"
    />
  </div>
);

export default Maintenance;
